<template>
    <div class="content-wrapper">
    <!-- Content Header (Page header) -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1 class="m-0 text-dark">Role Wise Menu Privilege</h1>
          </div><!-- /.col -->
          <div class="col-sm-6"></div><!-- /.col -->
        </div><!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>
    <!-- /.content-header -->
    <!-- Main content -->
    <section class="content">
      <div class="container-fluid">
        <div class="card">
            <div class="card-body">    
                <b-overlay :show="loader">
                    <div class="row">
                        <div class="col-lg-8 offset-lg-2 col-md-8 offset-md-2">
                            <div class="row">
                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                    <div class="form-group">
                                        <label for="role_id">Role <span class="text-danger" title="Required">*</span></label>
                                        <select id="role_id" class="form-control" v-model="form.role_id">
                                            <option value="0">Select</option>
                                            <option v-for="(role, index) in roleList" :key="index" :value="role.id">{{ role.text }}</option>
                                        </select>
                                        <span class="text-danger" v-if="errors.name">{{ errors.name[0] }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-2" v-for="(tmpModule, indx) in form.moduleMenus" :key="indx" style="border:1px solid">
                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                    <h4>{{ locale == 'bn' ? tmpModule.module_name_bn : tmpModule.module_name }}</h4>
                                </div>
                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                    <div class="form-group">      
                                        <div class="custom-control custom-checkbox mb-2" v-for="(tmpMenu, index1) in tmpModule.menus" :key="index1">
                                            <input class="custom-control-input" type="checkbox" :id="tmpMenu.id" :value="tmpMenu.id" v-model="tmpMenu.is_check">
                                            <label :for="tmpMenu.id" class="custom-control-label">{{ tmpMenu.name }}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-xl-9 col-lg-9 col-md-9 col-sm-6 col-xs-12">
                                </div>
                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12 text-right">
                                    <div class="form-group">
                                        <button type="button" class="btn btn-info mr-2" @click.prevent="save">Save</button>
                                        <button type="button" class="btn btn-danger">Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </b-overlay>
            </div>
        </div>
      </div><!-- /.container-fluid -->
    </section>
    <!-- /.content -->
  </div>
</template>
<script>
import config from '@/config'
export default {
    name:'MenuPrivilege',
    data(){
        return {
            loader: false,
            roleMenuIds: [],
            form: {
                role_id: 0,
                moduleMenus:  []
            },
            errors: []
        }
    },
    created(){
        this.getRoleMenu()
    },
    watch: {
        'form.role_id': function (newVal, oldVal) {
            if (newVal != oldVal) {
                this.getRoleMenu()
            }
        }
    },
    computed: {
        roleList () {
            return this.$store.state.commonObj.roleList.filter(role => role.id != 1)
        },
        locale () {
            return this.$store.state.language
        }
    },
    methods:{
        async getRoleMenu () {
            this.loader = true
            const params = { role_id: this.form.role_id }
            const response = await config.getData('/role-menu', params)
            this.loader = false
            if (response.success) {
                this.form.moduleMenus = this.getSelectedMenuCheck(response.moduleMenus, response.roleMenuIds)
                this.roleMenuIds = response.roleMenuIds
            } else {
                this.form.moduleMenus = []
                this.roleMenuIds = []
            }
        },
        getSelectedMenuCheck (moduleMenus, roleMenuIds) {
            return moduleMenus.map(modules => {
                const tmpModules = modules.menus.map(menu => {
                    const tmpMenuId = roleMenuIds.find(tmp => tmp.menu_id == menu.id)
                    return Object.assign(menu, { is_check: typeof tmpMenuId != 'undefined' ? true : false })
                })
                return Object.assign(modules, tmpModules)
            })
        },
        async save(){
            this.loader = true
            const response = await config.postData('/role-menu/store', this.form)
            this.loader = false
            if (response.status == 201) {        
                this.$toast.success({
                    title: 'Success',
                    message: 'Role wise permission created successfully',
                    color: '#218838'
                }) 
                this.getRoleMenu()
            } else {
                this.$toast.error({
                    title: 'Error',
                    message: 'Sorry, something went wrong',
                    color: '#dc3545'
                })   
            }
        },
    }
}
</script>